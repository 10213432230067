import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
Swiper.use([Navigation, Pagination]);
Swiper.use([Autoplay]);

const swiperreviews = new Swiper(".reviews__slider", {
  spaceBetween: 34,
  loop: false,
  grabCursor: true,
  navigation: {
    nextEl: ".slider-reviews-next",
    prevEl: ".slider-reviews-prev",
  },
  slidesPerView: 3,
  /*autoplay: true,
  autoplay: {
    delay: 2000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },*/
  lazy: true,
  breakpoints: {
    0: {
      slidesPerView: 1,
      spaceBetweenSlides: 15,
    },
    400: {
      slidesPerView: 1,
      spaceBetweenSlides: 15,
    },
    // when window width is <= 499px
    855: {
      slidesPerView: 2,
      spaceBetweenSlides: 15,
    },
    // when window width is <= 999px
    1200: {
      slidesPerView: 2,
      spaceBetweenSlides: 25,
    },

    1216: {
      slidesPerView: 3,
      spaceBetween: 34,
    },
  },
});

const swiperPrev = document.getElementById("swiperPrev");
const swiperNext = document.getElementById("swiperNext");

swiperPrev.addEventListener("click", () => {
  swiperreviews.slidePrev();
});
swiperNext.addEventListener("click", () => {
  swiperreviews.slideNext();
});
