function init() {
  let map = new ymaps.Map("map", {
    center: [25.798991222112196, -80.25126045713257],
    zoom: 12,
  });

  let placemark = new ymaps.Placemark(
    [25.798991222112196, -80.25126045713257],
    {},
    {}
  );

  map.controls.remove("geolocationControl"); // удаляем геолокацию
  map.controls.remove("searchControl"); // удаляем поиск
  map.controls.remove("trafficControl"); // удаляем контроль трафика
  map.controls.remove("typeSelector"); // удаляем тип
  map.controls.remove("fullscreenControl"); // удаляем кнопку перехода в полноэкранный режим
  map.controls.remove("zoomControl"); // удаляем контрол зуммирования
  map.controls.remove("rulerControl"); // удаляем контрол правил

  map.geoObjects.add(placemark);
}

ymaps.ready(init);
